import { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';

import { AppLayout } from './components/layouts/AppLayout.jsx';
import { loadAllMicroApps } from './MicroApps.js';
import { HomePage } from './pages/Home.jsx';
import LoginPage from './pages/Login.jsx';
import LogoutPage from './pages/Logout.jsx';
import oktaAuth, { getUserData } from './utils/okta.js';

import { NavigationProvider } from './components/NavigationProvider.jsx';
import { config } from './config/client/config.js';
import { SidebarProvider } from './context/SidebarContext.jsx';
import { TanstackReactQueryProvider } from './TanstackReactQueryProvider.jsx';
import { identifyUserForAnalytics } from './utils/analytics.js';
import Api from './utils/Api.js';

export const App = () => {
  let [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    loadAllMicroApps();

    // Register event listener for okta auth state changes
    oktaAuth.init(async authState => {
      console.log('authState', authState);
      const { isAuthenticated } = authState;
      if (!isAuthenticated) return;

      // Get user email from okta auth token
      const { email } = getUserData();
      if (!email) return;
      
      // Identify user for analytics
      // No need to "await" this
      identifyUserForAnalytics(email);

      // Update or create admin user from auth token
      await Api.get(config.adminServiceUrl + '/addUser');

      // Render the app
      setIsAuthenticated(true);
    });
  }, []);

  return (
    <TanstackReactQueryProvider>
      <NavigationProvider>
        <SidebarProvider>
          {isAuthenticated ? (
            <Router>
              <Routes>
                <Route path='/login/oidc/callback' element={<LoginPage isAuthenticated={true} />} />
                <Route path='/logout/oidc/callback' element={<LogoutPage />} />
                <Route path='/' element={<AppLayout />}>
                  <Route index element={<HomePage />} />
                  <Route path='ui/issue/*' subapp={true} />
                  <Route path='ui/admin/*' subapp={true} />
                  <Route path='ui/maintenance/*' subapp={true} />
                  <Route path='ui/vantage-dashboard/*' subapp={true} />
                  <Route path='ui/reflow/*' subapp={true} />
                  <Route path='ui/cbm/*' subapp={true} />
                  <Route path='ui/smart-cleaning/*' subapp={true} />
                  <Route path='ui/pipe-monitoring/*' subapp={true} />
                  <Route path='*' element={<div>Not Found</div>} />
                </Route>

                {/* provide demo routes as physical page */}
                <Route path='/demo' element={<AppLayout />}>
                  <Route index element={<HomePage />} />
                  <Route path='/demo/ui/issue/*' subapp={true} />
                  <Route path='/demo/ui/admin/*' subapp={true} />
                  <Route path='/demo/ui/maintenance/*' subapp={true} />
                  <Route path='/demo/ui/vantage-dashboard/*' subapp={true} />
                  <Route path='/demo/ui/reflow/*' subapp={true} />
                  <Route path='/demo/ui/cbm/*' subapp={true} />
                  <Route path='/demo/ui/smart-cleaning/*' subapp={true} />
                  <Route path='/demo/ui/pipe-monitoring/*' subapp={true} />
                  <Route path='*' element={<div>Not Found</div>} />
                </Route>
              </Routes>
            </Router>
          ) : (
            <Router>
              <Routes>
                <Route path='/login/oidc/callback' element={<LoginPage />} />
                <Route path='/logout/oidc/callback' element={<LogoutPage />} />
                <Route path='*' element={<LoginPage />} />
              </Routes>
            </Router>
          )}
        </SidebarProvider>
      </NavigationProvider>
    </TanstackReactQueryProvider>
  );
};
export default App;
